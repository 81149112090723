import { ODataFilter, FieldSort } from '@zing/grid';
import { ShipmentStatus } from '../../models/shipment';

export class ShipmentSummaryConfig {
  filter: ODataFilter;
  gridId: string;
  sort: FieldSort;
}

export enum ShipmentSummaryGridId {
  Saved = 'savedShipmentsSummary',
  Processed = 'processedShipmentsSummary'
}

export const SavedShipmentsConfig: ShipmentSummaryConfig = {
  filter: {
    logic: 'or',
    filters: [
      { field: 'status',
        operator: 'eq',
        value: ShipmentStatus.Saved
      },
      { field: 'status',
        operator: 'eq',
        value: ShipmentStatus.EditRequired
      },
      { field: 'status',
        operator: 'eq',
        value: ShipmentStatus.ReadyForPurchase
      },
      { field: 'status',
        operator: 'eq',
        value: ShipmentStatus.ApprovalRequired
      },
      { field: 'status',
        operator: 'eq',
        value: ShipmentStatus.NeedsReview
      }
    ]
  },
  gridId: ShipmentSummaryGridId.Saved,
  sort: { field: 'createDate', dir: 'desc' }
};

export const ProcessedShipmentsConfig: ShipmentSummaryConfig = {
  filter: {
    logic: 'and',
    filters: [
      { field: 'status',
        operator: 'neq',
        value: ShipmentStatus.Saved
      },
      { field: 'status',
        operator: 'neq',
        value: ShipmentStatus.EditRequired
      },
      { field: 'status',
        operator: 'neq',
        value: ShipmentStatus.ReadyForPurchase
      },
      { field: 'status',
        operator: 'neq',
        value: ShipmentStatus.ApprovalRequired
      },
      { field: 'status',
        operator: 'neq',
        value: ShipmentStatus.NeedsReview
      }
    ]
  },
  gridId: ShipmentSummaryGridId.Processed,
  sort: { field: 'shipmentCreated', dir: 'desc' }
};
