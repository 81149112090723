import { FilterDefinition } from '../modules/shared/models/filter-definition.model';
import { CompositeFilterDescriptor, FilterDescriptor, toODataString } from '@progress/kendo-data-query';
import { DateTimeRegex, GuidQueryRegex } from './regex';

export interface ODataModel {
  filter: string;
  orderBy: string;
  skip: number;
  top: number;
}
// TODO: PRIMENG - Remove after PRIMENG update
function skipODataStringInFilter(queryFilter: CompositeFilterDescriptor | FilterDescriptor) {
  if ('value' in queryFilter) {
    queryFilter = queryFilter as FilterDescriptor;
    if (!queryFilter.value || typeof (queryFilter.value) !== 'string') {
      return {
        ...queryFilter,
        field: queryFilter.field
      };
    }

    const result = {
      ...queryFilter,
      field: queryFilter.field,
      value: queryFilter.value.replace(/'/g, function (match) { return '\\\''; })
    } as FilterDescriptor;
    return result;
  } else {
    queryFilter = queryFilter as CompositeFilterDescriptor;
    return {
      ...queryFilter,
      filters: queryFilter.filters.map(el => skipODataStringInFilter(el))
    };
  }
}
// TODO: PRIMENG - Remove after PRIMENG update
export function createDataQueryModel(filter: FilterDefinition): ODataModel {
  if (filter?.filter?.filters) {
    // escape single quotes, create new filter so that kendo search is not showing escape chars
    const copy = {
      ...filter.filter,
      filters: filter.filter?.filters?.map(el => skipODataStringInFilter(el))
    };
    filter = {
      ...filter,
      filter: copy
    };
  }

  return createODataModel(filter);
}

// TODO: PRIMENG - Remove after PRIMENG update
function createODataModel(filter: FilterDefinition) {
  const oDataModel = {
    filter: '',
    orderBy: '',
    skip: 0,
    top: 0
  };

  const filterOptions = `${toODataString(filter)}`.split('&');
  for (let i = 0; i < filterOptions.length; i++) {
    const item = filterOptions[i].split('=');

    switch (item[0]) {
      case '$skip': oDataModel.skip = +item[1];
        break;
      case '$top': oDataModel.top = +item[1];
        break;
      case '$orderby': oDataModel.orderBy = item[1];
        break;
      case '$filter': {
        // we need to decode characters
        item[1] = unescape(item[1]);
        // odata adds two quotes when you search for one so we need to replace it before search
        const replaceChars = { '\\\'\'': '\\\'' };
        item[1] = item[1].replace(/\\''/g, function (match) { return replaceChars[match]; });
        // because the backend uses an older version of OData (older than v4) need to replace the date strings
        item[1] = item[1].replace(new RegExp(DateTimeRegex, 'g'), function (match) { return `datetime${match}`; });
        item[1] = item[1].replace(new RegExp(GuidQueryRegex, 'g'), function (match) { return `guid${match}`; });
        oDataModel.filter = item[1];
      } break;
    }
  }
  return oDataModel;
}
