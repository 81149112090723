import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd, Event, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { ViewportScroller, isPlatformBrowser } from '@angular/common';
import {
  // eslint-disable-next-line max-len
  OidcSecurityService, AuthStateResult, ValidationResult, PublicEventsService, EventTypes } from 'angular-auth-oidc-client';
import { AnalyticsService } from './modules/shared/services/analytics.service';
import { environment } from '../environments/environment';
import { UnsupportedBrowserService } from './modules/shared/services/unsupported-browsers.service';
import { NgbPopoverConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from './modules/shared/services/google-analytics.service';
import { GTMService } from './modules/shared/services/gtm.service';
import { Meta } from '@angular/platform-browser';
import { filter, skip, take } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { localStorageKeys } from './modules/membership/modules/shipping/utils/shipment-constants';
import { ShipmentSummaryGridId } from './modules/membership/modules/shipping/components/shipment-summary-page/shipment-summary-config';
import { ThemeService } from './modules/shared/services/theme.service';
import { sessionStorageKeys } from './modules/membership/modules/billing/utils/billing-constants';
import { AuthService } from './modules/core/services/auth.service';
import { AppConfigurationService } from './modules/core/services/app-configuration.service';
import { MixPanelModuleTracker } from './modules/shared/services/mix-panel-module-tracker.service';

declare let require: any;

@Component({
  selector: 'z-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ NgbTooltipConfig, NgbPopoverConfig ]
})
export class AppComponent {

  readonly urlsToSkipScrollToTop = [
    '/policy/business/dashboard'
  ];

  loading: boolean;
  maxNumberOfLoginRetries = 3;
  starting = true;

  responsivePopover$ = this.breakpointObserver.observe('(max-device-width: 1024px)');

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private translate: TranslateService,
    private oidcSecurityService: OidcSecurityService,
    private analyticsService: AnalyticsService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private unsupportedBrowserService: UnsupportedBrowserService,
    private gtmService: GTMService,
    private metaService: Meta,
    private eventService: PublicEventsService,
    private authService: AuthService,
    private themeService: ThemeService,
    tooltipConfig: NgbTooltipConfig,
    popoverConfig: NgbPopoverConfig,
    private breakpointObserver: BreakpointObserver,
    private viewportScroller: ViewportScroller,
    private appConfigurationService: AppConfigurationService,
    private routeTracker: MixPanelModuleTracker
  ) {

    this.authService.setAccessToken();

    // this is used for auto login
    this.oidcSecurityService.isAuthenticated$.pipe(skip(1))
      .subscribe(({ isAuthenticated }) => {
        this.authService.setAccessToken();
        localStorage.setItem('oidcIsAuthenticated', isAuthenticated ? 'true' : 'false');

        if (!isAuthenticated) {
          sessionStorage.removeItem('sessionLevelModalDisplay');
          sessionStorage.removeItem('sessionLevelTaxDocBannerHide');
          sessionStorage.removeItem('paymentRetryCounter');
          sessionStorage.removeItem('payment_failed_attempts');
          sessionStorage.removeItem('shippingRetryCounter');
          sessionStorage.removeItem('creditCardRetryCounter');
          sessionStorage.removeItem('labelErrorRetryCounter');
          sessionStorage.removeItem('systemRetryCounter');
          sessionStorage.removeItem('downloadRetryCounter');
          sessionStorage.removeItem('RequestedImages');
          this.resetShipmentSummaryFilters();
          this.resetBillingAndPaymentsFilters();
        } else {
          localStorage.removeItem('access_token');
        }
      });

    tooltipConfig.triggers = 'hover focus:blur';

    this.responsivePopover$.pipe(filter(res => !!res), take(1)).subscribe(res => {
      if (res && res.matches) {
        tooltipConfig.triggers = 'click';
        popoverConfig.triggers = 'click';
      }
    });

    this.metaService.updateTag({
      property: 'og:image',
      content: `${environment.baseURL}/assets/images/zing-logo-meta.png`
    });

    if(environment.excludeIndexing){
      this.metaService.addTags([
        { name: 'robots', content: 'noindex' },
      ]);
    }

    // Add Google Tag Manager for tracking
    this.gtmService.addGTM();

    // Tracking route changes with custom analytics
    analyticsService.startTracking();
    analyticsService.trackActiveProducts();

    // Tracking route changes with theme service
    this.themeService.trackActiveModule();

    // Tracking route changes with google analytics
    this.googleAnalyticsService.startPageTracking();

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    translate.setTranslation('en', require('../assets/i18n/en.json'));

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    this.router.events
      .subscribe(event => {
        // Scroll to top if accessing a page, not via browser history stack
        if (event instanceof NavigationEnd) {
          if (isPlatformBrowser(platformId)) {
            if (this.urlsToSkipScrollToTop.every(urlToSkip => !event.url.includes(urlToSkip))) {
              this.viewportScroller.scrollToPosition([0, 0]);
            }
            this.starting = false;
          }
        }
        this.checkRouterEvent(event);
      });

    /**
     * set userContext for telemetry
     */
    this.authService.getUserData()
      .pipe(filter(data => !!data))
      .subscribe(userData => {
        this.analyticsService.setUsername(userData?.sub);
      });

    this.getListOfBrowsers();

    this.eventService.registerForEvents()
      .pipe(filter(notification => notification.type === EventTypes.NewAuthenticationResult))
      .subscribe(
        authorizationResult => {
          this.authService.setAccessToken();
          this.onAuthorizationResultComplete(authorizationResult.value);
        });

    this.oidcSecurityService.checkSessionChanged$
      .subscribe(
        sessionChanged => {
          this.onCheckSessionChangedComplete(sessionChanged);
        }
      );

  }

  private onAuthorizationResultComplete(authorizationResult: AuthStateResult) {
    if (environment.authLogConsoleDebugActive) {
      console.log('Auth result received Authorized:' + authorizationResult.isAuthenticated
        + ' validationResult:' + authorizationResult.validationResult);
    }

    if (authorizationResult.isAuthenticated === false &&
      authorizationResult.validationResult === ValidationResult.StatesDoNotMatch) {
      let reTryAttempt = Number(localStorage.getItem('reTryAttempt'));

      reTryAttempt = reTryAttempt > 0 ? ++reTryAttempt : 1;
      localStorage.setItem('reTryAttempt', reTryAttempt.toString());

      if (reTryAttempt < this.maxNumberOfLoginRetries + 1) {
        this.oidcSecurityService.authorize();
      } else {
        this.router.navigateByUrl('/signinerror?cssource=validationfailed');
      }
    }
  }

  private onCheckSessionChangedComplete(sessionchanged: boolean) {
    if (environment.authLogConsoleDebugActive) {
      console.log('CheckSession result received sessionChanged :' + sessionchanged);
    }

    if (sessionchanged) {
      this.oidcSecurityService.forceRefreshSession();
      this.router.navigate(['/login']);
    }
  }
  public getListOfBrowsers() {
    if (!this.unsupportedBrowserService.checkForBrowserValidity()) {
      this.analyticsService.analytics.eventTrack.next({
        action: 'detectedBrowser',
        properties: {
          'os': this.unsupportedBrowserService.detectedBrowser.getOSName(),
          'browser': this.unsupportedBrowserService.detectedBrowser.getBrowserName(),
          'browserVersion': this.unsupportedBrowserService.detectedBrowser.getBrowserVersion()
        }
      });
      this.router.navigate(['/unsupportedBrowsers']);
    }
  }

  checkRouterEvent(routerEvent: Event) {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.loading = false;
    }
  }

  private resetShipmentSummaryFilters() {
    localStorage.removeItem(`${localStorageKeys.filters}-${ShipmentSummaryGridId.Saved}`);
    localStorage.removeItem(`${localStorageKeys.filters}-${ShipmentSummaryGridId.Processed}`);
    localStorage.removeItem(`${localStorageKeys.sort}-${ShipmentSummaryGridId.Saved}`);
    localStorage.removeItem(`${localStorageKeys.sort}-${ShipmentSummaryGridId.Processed}`);
    localStorage.removeItem(`${localStorageKeys.dateRangeFilterType}-${ShipmentSummaryGridId.Saved}`);
    localStorage.removeItem(`${localStorageKeys.dateRangeFilterType}-${ShipmentSummaryGridId.Processed}`);
  }

  private resetBillingAndPaymentsFilters() {
    sessionStorage.removeItem(sessionStorageKeys.startDate);
    sessionStorage.removeItem(sessionStorageKeys.endDate);
    sessionStorage.removeItem(sessionStorageKeys.searchCriteria);
    sessionStorage.removeItem(sessionStorageKeys.paidStatementsPageSize);
    sessionStorage.removeItem(sessionStorageKeys.transactionsPageSize);
  }
}
