import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AnalyticsService } from './analytics.service';
import { MixPanelEventNamesConfig } from './mix-panel-event-names-config';

@Injectable({
  providedIn: 'root'
})
export class MixPanelModuleTracker {

  private readonly moduleRoutes = new Map<string, string>([
    ['ShippingModule', '/membership/shipping'],
    ['BillingModule', '/membership/billing'],
    ['AppraisalModule', '/appraisals'],
    ['JmccDashboardModule', '/jmcc'],
    ['MemoModule', '/memo'],
    ['JewelerPagesModule', '/jewelerpages'],
    ['LabGrownDiamondsModule', '/marketplace/labgrown'],
    ['NaturalDiamondsModule', '/marketplace/natural'],
    ['MarketplaceModule', '/marketplace'],
    ['CommercialLinesModule', '/policy/business']
  ]);

  private openedModule = '';

  constructor(private router: Router, private analyticsService: AnalyticsService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => this.getModuleFromUrl(event))
    ).subscribe((moduleName: string) => {
      if(moduleName !== '') {
        if (this.openedModule !== moduleName) {
          this.analyticsService.sendTrackingRequest(MixPanelEventNamesConfig.moduleOpened, {
            Name: moduleName
          });
          this.openedModule = moduleName;
        }
      } else {
        if(this.openedModule === moduleName) {
          this.openedModule = '';
        }
      }
    });
  }

  private getModuleFromUrl(event: any): string {
    for (const [moduleName, route] of this.moduleRoutes) {
      if (event.url.startsWith(route)) {
        return moduleName;
      }
    }
    return '';
  }
}
