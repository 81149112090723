import { DateRangeType } from '../../../../shared/enums/date-range-type.enum';
import { ShippingColumnName } from '../enums/shipping-column-type';
import { TextSearchFilterType } from '../enums/text-search-filter-type.enum';
import { ShippingColumn } from '../models/shipment';
import { FilterModalType } from '../enums/filter-modal.enum';

export const shipmentConstants = {
  parcelLimit: 70,
  characterLimit: 72,
  insuredValueForAdultSignature: 35000,
  purchaseWarningTimeout: 20000, // 20 seconds
  purchaseTimeout: 100000, // 100 seconds
  securitySealDisplayLimit: 75000,
  returnLabelLimit: 10000,
  maxResidentialDeclaredValue: 75000
};

export const shipmentErrors = {
  approvalCodeError: 'Invalid Approval code',
  excelFileError: 'Invalid structure of excel file.',
  virusInfectedFile: 'Invalid or virus infected file.',
  invalidFileFormat: 'Invalid file format.',
  invalidFileName: 'Invalid file name.',
  approvalRequiredMessage: 'Approval Contact required for this shipment'
};

export const additionalConsent = {
  fnol: 'FNOL'
};

export const defaultGridColumnSettings: ShippingColumn[] = [
  { columnName: ShippingColumnName.CarrierTrackingId, columnData: 'carrierTrackingId', columnTitle: 'Carrier Tracking #', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.TextSearch, isLocked: true, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.ZingTrackingNumber, columnData: 'zingTrackingNumber', columnTitle: 'Shipment ID', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.Status, columnData: 'status', columnTitle: 'Status', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.MultiCheck, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.Carrier, columnData: 'carrierName', columnTitle: 'Carrier', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.MultiCheck, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToCompany, columnData: 'shipTo', columnTitle: 'Ship to Company', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToContact, columnData: 'shipToContact', columnTitle: 'Ship to Contact', isVisible: true, isMandatory: true, filterable : true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.DeclaredValue, columnData: 'declaredValue', columnTitle: 'Declared Value', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.Combined, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipmentCreated, columnData: 'shipmentCreated', columnTitle: 'Transaction Date', isVisible: true, isMandatory: true, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipmentCost, columnData: 'shipmentCost', columnTitle: 'Shipment Cost', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.Combined, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.Void, columnData: 'voided', columnTitle: 'Void', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.MultiCheck, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromAddress, columnData: 'shipFromAddress', columnTitle: 'Ship from Address', isVisible: true, isMandatory: true, filterable : true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 }
];

export const gridColumnSettings: ShippingColumn[] = [
  { columnName: ShippingColumnName.InsuredValue, columnData: 'insuredValue', columnTitle: 'Insured Value', isVisible: false, isMandatory: false, filterable: true, filterModalType: FilterModalType.Combined, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ClientId, columnData: 'clientID', columnTitle: 'Client ID', isVisible: false, isMandatory: false, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  // { columnName: ShippingColumnName.AccountName, columnData: 'accountName', columnTitle: 'Account Name', isVisible: false, isMandatory: false, isLocked: false, sortable: true, orderNo: 0 },
  { columnName: ShippingColumnName.Signature, columnData: 'signature', columnTitle: 'Signature', isVisible: false, isMandatory: false, filterable :false, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.Packaging, columnData: 'packaging', columnTitle: 'Packaging', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.Dimensions, columnData: 'dimensions', columnTitle: 'Dimensions', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.Service, columnData: 'service', columnTitle: 'Service', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.ShipmentName, columnData: 'name', columnTitle: 'Shipment Name', isVisible: false, isMandatory: false, filterable :true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.Weight, columnData: 'weight', columnTitle: 'Weight', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.SendersReference, columnData: 'sendersReference', columnTitle: 'Reference 1', isVisible: false, isMandatory: false , filterable :true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromCompany, columnData: 'shipFrom', columnTitle: 'Ship from', isVisible: false, isMandatory: false, filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromContact, columnData: 'shipFromContact', columnTitle: 'Ship from Contact', isVisible: false, isMandatory: false, filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromZipCode, columnData: 'shipFromZipCode', columnTitle: 'Ship from Zip Code', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromCountry, columnData: 'shipFromCountry', columnTitle: 'Ship from Country', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ReceiversReference, columnData: 'receiversReference', columnTitle: 'Reference 2', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.Residential, columnData: 'residential', columnTitle: 'Residential', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToAddress, columnData: 'shipToAddress', columnTitle: 'Ship to Address', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToZipCode, columnData: 'shipToZipCode', columnTitle: 'Ship to Zip Code', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToCountry, columnData: 'shipToCountry', columnTitle: 'Ship to Country', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  // Allowing all users to see Ship-Based Billing column
  // { columnName: ShippingColumnName.ScanBasedBilling, columnData: 'scanBasedBilling', columnTitle: 'Scan-Based Billing', isVisible: false, isMandatory: false, filterable :true, filterModalType: FilterModalType.MultiCheck, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ScanBasedBilling, columnData: 'scanBasedBilling', columnTitle: 'Scan-Based Billing', isVisible: false, isMandatory: false, filterable :true, filterModalType: FilterModalType.MultiCheck, isLocked: false, sortable: true, orderNo: 1000 },
  { columnName: ShippingColumnName.ShipFromCity, columnData: 'shipFromCity', columnTitle: 'Ship From City', isVisible: false, isMandatory: false, filterable: true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromState, columnData: 'shipFromState', columnTitle: 'Ship From State', isVisible: false, isMandatory: false, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToCity, columnData: 'shipToCity', columnTitle: 'Ship To City', isVisible: false, isMandatory: false, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToState, columnData: 'shipToState', columnTitle: 'Ship To State', isVisible: false, isMandatory: false, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.CarrierShipDate, columnData: 'carrierShipDate', columnTitle: 'Carrier Ship Date', isVisible: false, isMandatory: false, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.CarrierDeliveryDate, columnData: 'carrierDeliveryDate', columnTitle: 'Carrier Delivery Date', isVisible: false, isMandatory: false, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.VoidedOn, columnData: 'voidedOn', columnTitle: 'Voided On', isVisible: false, isMandatory: false, filterable: false, isLocked: false, sortable: true, orderNo: 99 },
  { columnName: ShippingColumnName.ClaimReportingPeriod, columnData: 'claimReportingPeriod', columnTitle: 'Claim Reporting Period', isVisible: false, isMandatory: false, filterable: true, filterModalType: FilterModalType.MultiCheck, isLocked: false, sortable: false, orderNo: 100 }
];

export const savedShipmentsDefaultColumns: ShippingColumn[] = [
  { columnName: ShippingColumnName.ZingTrackingNumber, columnData: 'zingTrackingNumber', columnTitle: 'Shipment ID', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.Status, columnData: 'status', columnTitle: 'Status', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.MultiCheck, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.Carrier, columnData: 'carrierName', columnTitle: 'Carrier', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.MultiCheck, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToCompany, columnData: 'shipTo', columnTitle: 'Ship to Company', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToContact, columnData: 'shipToContact', columnTitle: 'Ship to Contact', isVisible: true, isMandatory: true, filterable : true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.SendersReference, columnData: 'sendersReference', columnTitle: 'Reference 1', isVisible: true, isMandatory: true , filterable :true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.DeclaredValue, columnData: 'declaredValue', columnTitle: 'Declared Value', isVisible: true, isMandatory: true, filterable: true, filterModalType: FilterModalType.Combined, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.CreatedDate, columnData: 'createDate', columnTitle: 'Created Date', isVisible: true, isMandatory: true, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.Service, columnData: 'service', columnTitle: 'Service', isVisible: true, isMandatory: true , filterable :false, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.Signature, columnData: 'signature', columnTitle: 'Signature', isVisible: true, isMandatory: true, filterable :false, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToCity, columnData: 'shipToCity', columnTitle: 'Ship To City', isVisible: true, isMandatory: true, filterable: false, isLocked: false, sortable: true, orderNo: -1 }
];

export const savedShipmentsGridColumnSettings: ShippingColumn[] = [
  { columnName: ShippingColumnName.ClientId, columnData: 'clientID', columnTitle: 'Client ID', isVisible: false, isMandatory: false, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.Dimensions, columnData: 'dimensions', columnTitle: 'Dimensions', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.InsuredValue, columnData: 'insuredValue', columnTitle: 'Insured Value', isVisible: false, isMandatory: false, filterable: true, filterModalType: FilterModalType.Combined, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.Packaging, columnData: 'packaging', columnTitle: 'Packaging', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: false, orderNo: -1 },
  { columnName: ShippingColumnName.ReceiversReference, columnData: 'receiversReference', columnTitle: 'Reference 2', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.Residential, columnData: 'residential', columnTitle: 'Residential', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipmentName, columnData: 'name', columnTitle: 'Shipment Name', isVisible: false, isMandatory: false, filterable :true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromCompany, columnData: 'shipFrom', columnTitle: 'Ship From', isVisible: false, isMandatory: false, filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromContact, columnData: 'shipFromContact', columnTitle: 'Ship From Contact', isVisible: false, isMandatory: false, filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromAddress, columnData: 'shipFromAddress', columnTitle: 'Ship From Address', isVisible: false, isMandatory: false, filterable : true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromCity, columnData: 'shipFromCity', columnTitle: 'Ship From City', isVisible: false, isMandatory: false, filterable: true, filterModalType: FilterModalType.TextSearch, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromState, columnData: 'shipFromState', columnTitle: 'Ship From State', isVisible: false, isMandatory: false, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromZipCode, columnData: 'shipFromZipCode', columnTitle: 'Ship From Zip Code', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipFromCountry, columnData: 'shipFromCountry', columnTitle: 'Ship From Country', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToAddress, columnData: 'shipToAddress', columnTitle: 'Ship To Address', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToState, columnData: 'shipToState', columnTitle: 'Ship To State', isVisible: false, isMandatory: false, filterable: false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToZipCode, columnData: 'shipToZipCode', columnTitle: 'Ship To Zip Code', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.ShipToCountry, columnData: 'shipToCountry', columnTitle: 'Ship To Country', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: true, orderNo: -1 },
  { columnName: ShippingColumnName.Weight, columnData: 'weight', columnTitle: 'Weight', isVisible: false, isMandatory: false , filterable :false, isLocked: false, sortable: false, orderNo: -1 }
];

export const localStorageKeys = {
  dateRangeFilterType: 'shipmentDateRangeFilter',
  filters: 'shipmentSummaryFilters',
  sort: 'shipmentSummarySort',
  startDate: 'shipmentSummaryStartDate',
  endDate: 'shipmentSummaryEndDate',
  summaryDateRange: 'shipmentSummaryDateRange',
  pageSize: 'pageSize'
};

export const sessionStorageKeys = {
  reconSort: 'shipmentReconSort',
  reconPageSize: 'shipmentReconPageSize',
  reconDateRangeFilterType: 'shipmentReconDateRageType',
  reconDateRange: 'shipmentReconDateRange',
  reconFilters: 'shipmentReconFilters'
};

export const datePickerFilters = [
  { value: DateRangeType.Today, text: 'DateFilters.Today' },
  { value: DateRangeType.Last7Days, text: 'DateFilters.Last7Days' },
  { value: DateRangeType.CurrentMonth, text: 'DateFilters.CurrentMonth' },
  { value: DateRangeType.LastMonth, text: 'DateFilters.LastMonth' },
  { value: DateRangeType.Last3Months, text: 'DateFilters.Last3Months' },
  { value: DateRangeType.Last6Months, text: 'DateFilters.Last6Months' },
  { value: DateRangeType.AllHistory, text: 'DateFilters.AllHistory' },
  { value: DateRangeType.SpecificDateRange, text: 'DateFilters.SpecificRange'}
];

export const reconDatePickerFilters = [
  { value: DateRangeType.Last7Days, text: 'DateFilters.Last7Days' },
  { value: DateRangeType.Last60Days, text: 'DateFilters.Last60Days' },
  { value: DateRangeType.SpecificDateRange, text: 'DateFilters.SpecificRange' }
];

export const destination = 'destination';
export const minimalDate = '1900';
export const uspsOnly = 'USPS Only';
