export enum ShippingColumnName {
  Value = 1,
  ClientId = 2,
  AccountName = 3,
  Signature = 7,
  Packaging = 8,
  Dimensions = 9,
  Carrier = 10,
  Service = 11,
  ShipmentName = 12,
  Weight = 16,
  ShipmentCost = 17,
  SendersReference = 18,
  ShipFromCompany = 19,
  ShipFromContact = 20,
  ShipFromAddress = 22,
  ShipFromZipCode = 23,
  ShipFromCountry = 24,
  ReceiversReference = 25,
  Residential = 26,
  ShipToCompany = 27,
  ShipToContact = 28,
  ShipToAddress = 29,
  ShipToZipCode = 31,
  ShipToCountry = 32,
  ZingTrackingNumber = 33,
  CarrierTrackingId = 34,
  Status = 35,
  ShipmentCreated = 36,
  DeclaredValue = 37,
  InsuredValue = 38,
  Void = 39,
  ECommOrders = 40,
  ShipVia = 41,
  ScanBasedBilling = 42,
  ShipFromCity = 43,
  ShipFromState = 44,
  ShipToCity = 45,
  ShipToState = 46,
  CarrierShipDate = 47,
  CarrierDeliveryDate = 48,
  VoidedOn = 49,
  CreatedDate = 50,
  ClaimReportingPeriod = 51
}

export enum ReconciliationColumnName {
  ShipmentId = 1,
  ShipmentGid = 2,
  PurchasedOn = 3,
  ReconciledOn = 4,
  TrackingNumber = 5,
  Reference = 6,
  FromCompany = 7,
  ToCompany = 8,
  ToContact = 9,
  Cargo = 10,
  Carrier = 11,
  Service = 12,
  Comment = 13,
  ReconAmount = 14,
  OriginalPrice = 15,
  TotalPrice = 16,
  InvoiceReference = 16,
  ClientId = 17
}
