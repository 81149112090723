export enum AnalyticsCustomEvents {
  InitiateShipment = 'InitiateShipment',

  InitiateShipmentAddressOrigin = 'InitiateShipment-SinglePage-Origin',

  InitiateShipmentAddressDestination = 'InitiateShipment-SinglePage-Destination',

  InitiateShipmentSpaAddress = 'InitiateShipment-SinglePage-Address',

  InitiateShipmentSpaDetails = 'InitiateShipment-SinglePage-Details',

  InitiateShipmentSpaServices = 'InitiateShipment-SinglePage-CarriersAndServices',

  InitiateShipmentReference = 'InitiateShipment-Reference',

  InitiateShipmentQuoteProcessing = 'InitiateShipment-QuoteProcessing',

  InitiateShipmentValidation = 'InitiateShipment-Validation',

  QuickQuote = 'QuickQuote',

  QuickQuoteProcessing = 'QuickQuote-Processsing',

  InitiateDiamondDetailsEmail = 'Marketplace-NatDiamond-InitiateDiamondDetailsEmail',

  DiamondDetailsEmailSent = 'DiamondDetailsEmailSent',

  DiamondQuestionEmailSent = 'DiamondQuestionEmailSent',

  DiamondImageRequestEmailSent = 'DiamondImageRequestEmailSent',

  InitiateSelectedDiamondsXlsExport = 'Marketplace-NatDiamond-InitiateSelectedDiamondsXlsExport',

  SelectedDiamondsXlsExportCompleted = 'SelectedDiamondsXlsExportCompleted',

  InitiateDiamondPageXlsExport = 'Marketplace-NatDiamond-InitiateDiamondPageXlsExport',

  DiamondPageXlsExportCompleted = 'DiamondPageXlsExportCompleted',

  InitiateDiamondPdfExport = 'Marketplace-NatDiamond-InitiateDiamondPdfExport',

  DiamondPdfExportCompleted = 'DiamondPdfExportCompleted',

  InitiateDiamondOrder = 'InitiateDiamondOrder',

  DiamondOrderCompleted = 'DiamondOrderCompleted',

  CreditCardSubmitClicked = 'CreditCardSubmitClicked',

  CreditCardSubmitClickedValid = 'CreditCardSubmitClickedValid',

  CreditCardGetTokenSuccess = 'CreditCardGetTokenSuccess',

  CreditCardGetTokenError = 'CreditCardGetTokenError',

  CreditCardNotBlankUrl = 'CreditCardNotBlankUrl',

  CreditCardUrlResetToBlank = 'CreditCardUrlResetToBlank',

  CreditCardSaveError = 'CreditCardSaveError',

  CreditCardSaveSuccess = 'CreditCardSaveSuccess',

  CreditCardPaypalError = 'CreditCardPaypalError',

  CreditCardPaypalSuccess = 'CreditCardPaypalSuccess',

  CreditCardPaypalNoSuccessOrError = 'CreditCardPaypalNoSuccessOrError',

  CreditCardLocationDoesntMatchDomain = 'CreditCardLocationDoesntMatchDomain',

  ClaimSignalRConnectionFailed = 'ClaimSignalRConnectionFailed',

  ClaimToSubmitCreated = 'ClaimToSubmitCreated',

  ClaimDocumentRemoving = 'ClaimDocumentRemoving',

  ClaimSubmittedFailed = 'ClaimSubmittedFailed',

  ClaimSubmitted = 'ClaimSubmitted',

  ClaimSubmitClick = 'ClaimSubmitClick',

  ClaimSubmitFormNotValid = 'ClaimSubmitFormNotValid',

  ClaimSubmitFormValid = 'ClaimSubmitFormValid',

  JMCCLandingPageLoaded = 'JMCCLandingPageLoaded',

  RegistrationNextStep = 'RegistrationNextStep',

  RegistarationCancelCompanySelection = 'RegistarationCancelCompanySelection',

  RegistrationBusinessSelected = 'RegistrationBusinessSelected',

  RegistrationCompanyNotFound = 'RegistrationCompanyNotFound',

  RegistrationCompanyHasBusinessNumber = 'RegistrationCompanyHasBusinessNumber',

  HubConnectionStart = 'HubConnectionStart',

  HubConnectionAlreadyStarted = 'HubConnectionAlreadyStarted',

  HubConnectionRetryStart = 'HubConnectionRetryStart',

  HubConnectionEnd = 'HubConnectionEnd',

  HubConnectionError = 'HubConnectionError',

  MarketplaceHubConnectionStart = 'MarketplaceHubConnectionStart',

  MarketplaceHubConnectionAlreadyStarted = 'MarketplaceHubConnectionAlreadyStarted',

  MarketplaceHubConnectionRetryStart = 'MarketplaceHubConnectionRetryStart',

  MarketplaceHubConnectionEnd = 'MarketplaceHubConnectionEnd',

  StudioQuoteHubConnectionStart = 'StudioQuoteHubConnectionStart',

  StudioQuoteHubConnectionAlreadyStarted = 'StudioQuoteHubConnectionAlreadyStarted',

  StudioQuoteHubConnectionRetryStart = 'StudioQuoteHubConnectionRetryStart',

  StudioQuoteHubConnectionEnd = 'StudioQuoteHubConnectionEnd',

  PaymentInformationReceived = 'PaymentInformationReceived',

  PaymentFailedStripeError = 'PaymentFailedStripeError',

  PaymentACHFailed = 'ACHPaymentFailed',

  PaymentACHCustomerCanceled = 'ACHPaymentCustomerCanceled',

  PaymentACHProcessing = 'ACHPaymentProcessing',

  PaymentACHConfirmationFailed = 'PaymentACHConfirmationFailed',

  PaymentCreditCardSucceded = 'PaymentCreditCardSucceeded',

  PaymentCreditCardError = 'PaymentCreditCardError',

  PaymentCreditCardProcessing = 'PaymentCreditCardProcessing',

  PaymentCreditCardRequirePaymentMethod = 'PaymentCreditCardRequirePaymentMethod',

  PaymentCreditCardUnknownError = 'PaymentCreditCardUnknownError',

  OrderProcessingStatusReceived = 'OrderProcessingStatusReceived',

  OrderProcessingStatusOrdered = 'OrderProcessingStatusOrdered',

  OrderProcessingStatusHasCodeChanged = 'OrderProcessingStatusHasCodeChanged',

  StudioPaymentCreditCardSucceeded = 'StudioPaymentCreditCardSucceeded',

  StudioPaymentCreditCardRequirePaymentMethod = 'StudioPaymentCreditCardRequirePaymentMethod',

  StudioPaymentCreditCardUnknownError = 'StudioPaymentCreditCardUnknownError',

  StudioPaymentCreditCardError = 'StudioPaymentCreditCardError',

  StudioHubConnectionEnd = 'StudioHubConnectionEnd',

  ReturnEligibilityActionClicked = 'Marketplace-NatDiamond-ReturnEligibilityActionClicked',

  ReturnEligibilityEmailRequested = 'ReturnEligibilityEmailRequested',

  SignInErrorLogUserData = 'SignInErrorLogUserData',

  JMCCLandingPageCTAQnA = 'JMCCLandingPage/JMCC-QnA-CTA',

  JMCCLandingPageCTAPurchased = 'JMCCLandingPage/JMCC-Pl-Portal-CTA',

  JewelerPagesThemes = 'JewelerPages-Themes',

  JewelerPagesCompanyInfo = 'JewelerPages-CompanyInfo',

  JewelerPagesBannerHeadline = 'JewelerPages-BannerHeadline',

  JewelerPagesServices = 'JewelerPages-Services',

  JewelerPagesAboutYou = 'JewelerPages-AboutYou',

  JewelerPagesUpcomingEvents = 'JewelerPages-UpcomingEvents',

  JewelerPagePublished = 'JewelerPagePublished',

  JewelerPagesWidgetClicked = 'JewelerPagesWidgetClicked',

  AuctionWidgetClicked = 'AuctionWidgetClicked',

  AuctionWidgetError = 'AuctionWidgetError',

  OnRedirectFromClaims = 'OnRedirectFromClaims',

  //#region JMCCDashboard
  JMCCDashboardContactUsSubmitClicked = '​JMCCDashboard/Contact-Us-Submit-CTA',

  JMCCDashboardResendButtonClicked = 'JMCCDashboard/Resend-CTA',

  JMCCDashboardContactUsButtonClicked = 'JMCCDashboard/Contact-US-CTA',

  JMCCDashboardAllTabClicked = 'JMCCDashboard/All-Tab-CTA',

  JMCCDashboardProcessedTabClicked = 'JMCCDashboard/Processed-Tab-CTA',

  JMCCDashboardNotProcessedTabClicked = 'JMCCDashboard/NotProcessed-Tab-CTA',

  JMCCDashboardSearchClicked = 'JMCCDashboard/Search-CTA',
  //#endregion

  MarketplaceNatDiamondSearchClicked = 'Marketplace-NatDiamond-SearchClicked',

  MarketplaceNatDiamondSaveSearchClicked = 'Marketplace-NatDiamond-SaveSearchClicked',

  MarketplaceNatDiamondEmailSelectedDiamondsClicked = 'Marketplace-NatDiamond-EmailSelectedDiamondsClicked',

  MarketplaceInitiateCompareDiamonds = 'Marketplace-NatDiamond-InitiateCompareDiamonds',

  MarketplaceLabDiamondSearchClicked = 'Marketplace-LabDiamond-SearchClicked',

  MarketplaceLabDiamondCompareClicked = 'Marketplace-LabDiamond-CompareClicked',

  MarketplaceLabDiamondGridViewClicked = 'Marketplace-LabDiamond-GridViewClicked',

  MarketplaceLabDiamondListViewClicked = 'Marketplace-LabDiamond-ListViewClicked',

  MarketplaceLabDiamondViewDetailsClicked = 'Marketplace-LabDiamond-ViewDetailsClicked',

  MarketplaceLabDiamondEmailZingClicked = 'Marketplace-LabDiamond-EmailZingClicked',

  //#region Appraisal Lab Integration
  AppraisalLabIntegrationReportIdCheckboxChecked = 'AppraisalLabIntegration/Report-ID-Present-CTA',

  AppraisalLabIntegrationReportIdEntered = 'AppraisalLabIntegration/Report-ID-Entered-CTA',

  AppraisalLabIntegrationReportFullMatchImported ='AppraisalLabIntegration/Report-Full-Match-Import-CTA',

  AppraisalLabIntegrationReportPartialMatchImported = ' AppraisalLabIntegration/Report-Partial-Match-Import-CTA',

  AppraisalLabIntegrationPartialDiamondSaved = 'AppraisalLabIntegration/Report-Partial-Diamond-Save-CTA',

  AppraisalLabIntegrationFullDiamondSaved = 'AppraisalLabIntegration/Report-Full-Diamond-Save-CTA',

  AppraisalLabIntegrationReportProblemDetected = 'AppraisalLabIntegration/Report-Problem-Detected',

  AppraisalLabIntegrationReportMismatchDetected = 'AppraisalLabIntegration/Report-Mismatch-Detected',

  AppraisalLabIntegrationReportFailureDetected = 'AppraisalLabIntegration/Report-Failure-Detected'
  //#endregion
}
