export const MixPanelEventNamesConfig = {
  shipmentTracked: 'Shipment Tracked',
  listFiltered: 'List Filtered',
  shipmentDetailsViewed: 'Shipment Details Viewed',
  listExported: 'List Exported',
  listRefreshed:'List Refreshed',
  listCustomized: 'List Customized',
  listViewed: 'List Viewed',
  actionItemsViewed: 'Action Items Viewed',
  formViewed: 'Form Viewed',
  formSubmitted: 'Form Submitted',
  shipmentVoided: 'Shipment Voided',
  shipmentMarkedAsDelivered: 'Shipment Marked as Delivered',
  documentDownloaded: 'Document Downloaded',
  formSectionCompleted: 'Form Section Completed',
  quoteSubmitted: 'Quote Submitted',
  formSaved: 'Form Saved',
  quoteSaved: 'Quote Saved',
  shipmentPurchased: 'Shipment Purchased',
  errorViewed: 'Error Viewed',
  moduleAccessRequested: 'Module Access Requested',
  signUpStepCompleted: 'Sign Up Step Completed',
  signUpCompleted: 'Sign Up Completed',
  paymentMethodAdded: 'Payment Method Added',
  quoteViewed: 'Quote Viewed',
  registrationStepCompleted: 'Registration Step Completed',
  registrationErrorViewed: 'Error Viewed',
  registrationFormCompleted: 'Registration Form Completed',
  registrationVerificationCodeResent: 'Verification Code Resent',
  registrationVerified: 'Registration Verified',
  dashboardViewed: 'Dashboard Viewed',
  dashboardMenuOpened: 'Dashboard Menu Opened',
  notificationPreviewOpened: 'Notification Preview Opened',
  notificationsOpened: 'Notifications Opened',
  helpdeskOpened: 'Helpdesk Opened',
  accessibilityOptionsOpened: 'Accessibility Options Opened',
  accountSettingsViewed: 'Account Settings Viewed',
  accountSettingsChanged: 'Account Settings Changed',
  teamMemberInvited: 'Team Member Invited',
  linkClicked: 'Link Clicked',
  bannerClicked: 'Banner Clicked',
  moduleOpened: 'Module Opened',
  newsModuleViewed: 'News Module Viewed',
  reconciliationDetailsViewed: 'Reconciliation Details Viewed',
  statementViewed: 'Statement Viewed',
  searchCompleted: 'Search Completed',
  inputFocused: 'Input Focused',
  supplyOrderCreated: 'Supply Order Created',
  externalLinkClicked: 'External Link Clicked'
} as const;

export type EventName = (typeof MixPanelEventNamesConfig) [keyof typeof MixPanelEventNamesConfig];
